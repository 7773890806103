<template>
  <v-container
    id="accounts"
    fluid
    tag="section"
  >
    <ValidationObserver
      ref="formAccount"
      class="w-full"
      v-slot="{ pristine }">
      <form key="formAccount">
        <div class="text-h3 font-weight-medium pb-4" v-if="selectedAccount && selectedAccount.id && selectedAccount.id > 0">
          Atualize os dados cadastrais da loja
        </div>
        <div class="text-h3 font-weight-medium pb-4" v-else>
          Informe os dados cadastrais da loja
        </div>
        <v-row no-gutters v-if="hasAdminPermission">
          <v-col cols="12" md="4" class="px-5">
            <ValidationProvider
              name="group"
              vid="group"
            >
              <v-select
                color="secondary"
                item-color="secondary"
                label="Selecione o grupo"
                :items="groups"
                v-model="selectedAccount.parent_id"
                item-text="account"
                item-value="id"
              >
              </v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4" class="px-5">
            <ValidationProvider
              name="feature"
              vid="feature"
            >
              <v-select
                color="secondary"
                item-color="secondary"
                label="Selecione o plano"
                :items="features"
                v-model="selectedAccount.feature_id"
                item-text="name"
                item-value="id"
              >
              </v-select>
            </ValidationProvider>
          </v-col>
          <v-col
              cols="12"
              sm="1" offset="3" class="px-2"
            >
              <ValidationProvider
                name="active"
                vid="active"
              >
                <v-switch
                  :false-value="0"
                  :true-value="1"
                  label="Ativo?"
                  v-model="selectedAccount.active"
                  class="mx-2"
                />
              </ValidationProvider>
            </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="4" class="px-5">
            <ValidationProvider
              name="accountName"
              vid="accountName"
            >
              <v-text-field
                v-model="selectedAccount.account"
                label="Nome loja/unidade"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="4" class="px-5">
            <ValidationProvider
              name="razaoSocial"
              vid="razaoSocial"
            >
              <v-text-field
                v-model="selectedAccount.detail.razao_social"
                label="Nome empresa"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="4" class="px-5">
            <ValidationProvider
              name="cnpj"
              vid="cnpj"
            >
              <v-text-field
                v-model="selectedAccount.detail.cnpj"
                label="CNPJ"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="px-5">
            <ValidationProvider
              name="nameRep"
              vid="nameRep"
            >
              <v-text-field
                v-model="selectedAccount.detail.name_rep"
                label="Nome do responsável"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" class="px-5">
            <ValidationProvider
              name="emailRep"
              vid="emailRep"
            >
              <v-text-field
                v-model="selectedAccount.detail.email_rep"
                label="E-mail do responsável"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="2">
            <v-btn @click="previousTab" block>
              Anterior
            </v-btn>
          </v-col>
          <v-col cols="12" md="2" offset="8">
            <v-btn @click.native="saveAndNextTab" block class="wizard-footer-right finish-button" color="primary">
                Próximo
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </v-container>
</template>

<script>

import { Account } from '@/code/models/User/Account'
import { AccountService } from '@/code/services/user/accountService';
import Swal from 'sweetalert2';
import { assistentSteps } from '@/code/util/constants';
import { Confirm, Loading } from '@/code/util/swalUtil';
import { EventBus } from '@/code/util/eventBus';
import { AuthUtil } from '@/code/util/authUtil';
import { EUserRole } from '@/code/enums/EUserRole';
import localStorageUtil from '@/code/util/localStorageUtil';

export default {
  data() {
    return {
      idAccount: 0,
      selectedAccount: new Account(0, {}),
      tipoAcao: { Cadastrar: 1, Revisar: 2 },
      tipoAcaoSelecionada: 0,
      hasAdminPermission: false,
      groups: [],
      features: []
    }
  },

  mounted() {
    this.updatePermissions();
    EventBus.$on("currentUserLoaded", this.updatePermissions);
    this.loadFeatures();
    if (this.$route.name === 'assistentEditAccount') {
      this.tipoAcaoSelecionada = this.tipoAcao.Revisar;
      this.loadAccount();
    } else {
      this.tipoAcaoSelecionada = this.tipoAcao.Cadastrar;
      this.selectedAccount = new Account(0, {});
    }
  },

  methods: {
    loadAccount: async function() {
      this.idAccount = this.$route.params.id;
      const that = this;
      if (this.idAccount && this.idAccount > 0) {
        const service = new AccountService();
        this.selectedAccount = await service.get(this.idAccount);
      } else {
        Swal.fire({
          title: 'Parâmetro inválido',
          text: 'A loja informada é invalida!',
          icon: 'error'
        })
        .then(
          (result) => {
            that.$router.replace({ name: 'assistentAction' });
          }
        )
      }
    },
    loadGroups: async function() {
      this.groups = [];
      if (this.hasAdminPermission) {
        if (!this.groups || this.groups.length === 0) {
          this.groups = [];
          const service = new AccountService();
          this.groups = await service.getGroups();
        }
      } else {
        this.groups = [];
        const group = localStorageUtil.getCurrentUserGroup();
        this.groups.push({ id: group.id, account: group.account });
        this.selectedAccount.parent_id = group.id;
      }
    },
    loadFeatures: async function() {
      this.features = [];
      const service = new AccountService();
      this.features = await service.getFeatures();
    },
    saveAndNextTab: async function() {
      const dirty = await this.$refs.formAccount.flags.dirty;
      if (dirty) {
        Loading.fire("Salvando dados da conta!");

        const toSave = {
          account: this.selectedAccount.account,
          active: this.selectedAccount.active,
          feature_id: this.selectedAccount.feature_id,
          detail: {
            razao_social: this.selectedAccount.detail.razao_social,
            name_rep: this.selectedAccount.detail.name_rep,
            email_rep: this.selectedAccount.detail.email_rep,
            cnpj: this.selectedAccount.detail.cnpj,
          }
        };
        const service = new AccountService();
        let sucesso = false;
        let idAccount = 0;
        if (this.selectedAccount.id > 0) {
          sucesso = await service.update(this.selectedAccount.id, toSave);
          idAccount = this.selectedAccount.id;
          Swal.close();
        } else {
          toSave.parent_id = this.selectedAccount.parent_id;
          toSave.active = 1;
          toSave.expire_date = new Date(2025, 1, 1);
          toSave.type = 'account';
          toSave.detail.company_name = this.selectedAccount.detail.razao_social;
          sucesso = await service.save(toSave);
          if (sucesso) {
            idAccount = sucesso.id;
            Swal.close();
          }
        }

        if (idAccount > 0) {
          EventBus.$emit('nextAssistentRoute', idAccount);
        }
      } else {
        EventBus.$emit('nextAssistentRoute');
      }
    },
    previousTab: async function() {
      const dirty = await this.$refs.formAccount.flags.dirty;
      if (dirty) {
        Confirm.fire('Os dados informados serão perdidos. Deseja voltar à etapa anterior?')
        .then(result => {
          if (result && result.isConfirmed) {
            EventBus.$emit('previousAssistentRoute');
          }
        });
      } else {
        EventBus.$emit('previousAssistentRoute');
      }
    },
    updatePermissions: function() {
      const authUtil = new AuthUtil();
      const rule = authUtil.getUserRole();
      this.hasAdminPermission = rule === EUserRole.Admin;
      this.loadGroups();
    },
  }
}
</script>

<style>

</style>
